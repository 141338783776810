<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getBusinesses"
              ></b-form-select>
              <label class="ml-3">
                Status Filter:&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.prospect_status"
                  class="ml-1 mr-1 status-filter"
                  size="sm"
                  :options="['all','leads','prospect']"
                  @change="getBusinesses"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <vue-json-to-csv :json-data="businesses" :csv-title="csvTitle()">
                <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
              </vue-json-to-csv>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getBusinesses"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <div class="table-responsive mb-0">
                <b-table
                  :items="businesses"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(lead_status)="data">
                    <strong class="text-primary" :class="{ 'text-success': data.item.lead_status === 'Lead' }">{{data.item.lead_status}}</strong>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <a :href="data.item.google_maps_url" class="mr-1" target="_blank"><img style="max-height: 30px" src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png"></a>
                    <router-link
                      :to="{
                        name: 'agency.businesses.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-success btn-sm ml-1"
                    >
                      View
                    </router-link>
                    <b-button
                      variant="link"
                      title="Delete"
                      size="lg"
                      class="btn-delete"
                      :disabled="loadingRemove === data.item.id"
                      @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalBusinesses }} businesses
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalBusinesses"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showDeleteModal" title="Delete agency">
      <p v-if="selectedBusiness"
        >Are you sure you want to delete business "{{
          selectedBusiness.name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showWelcomeModal" size="lg" title="Welcome">
      <div class="text-center">
        <h4 class="text-success">Thanks For Signing Up For Review Link Generator</h4>
        <p>
          We have autonomically created a demo account for you called Creekside
          Pizza. You can use this account to demo the system to your prospects.
          The account automatically updates daily so there always has fresh
          account data for you to demo. When you log in for the first time you
          will need to assign a number to the account before you can access it.
        </p>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="success" @click="showWelcomeModal = false"
          >Ok</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
export default {
  components:{
    VueJsonToCsv
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'small_date', label: 'Date', sortable: true },
          { key: 'business_name', label: 'Business', sortable: true },
          { key: 'full_name', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'phone_number', label: 'Phone', sortable: true },
          { key: 'lead_status', label: 'Status'},
          { key: 'actions', label: 'G Link', class:'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          prospect_status:'all',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showWelcomeModal: false,
    }
  },

  computed: {
    businesses() {
      return this.$store.getters['business/all'] || []
    },

    totalBusinesses() {
      return this.$store.getters['business/total']
    },

    reload() {
      return this.$store.getters['business/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.$store.dispatch('business/setCurrentPage', 'index')
  },

  mounted() {
    if (this.$store.getters['business/all'] === null) {
      this.getBusinesses()
    } else {
      this.datatable.queries = this.$store.getters['business/queries']
    }

    if (this.user.agency.is_new) {
      this.showWelcomeModal = true

      this.$store.dispatch('agency/toggleIsNew', this.user.agency)
    }
  },

  methods: {
    csvTitle(){
      return 'review-link-generator-'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBusinesses()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true

      this.$store
        .dispatch('business/getAll', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then((res) => {
          this.loadingBusinesses = false
        })
        .catch(() => {
          this.loadingBusinesses = false
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedBusiness.id
      this.$store
        .dispatch('business/archive', this.selectedBusiness.id)
        .then(() => {
          this.loadingRemove = null
          this.getBusinesses()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  .status-filter{
    text-transform: capitalize;
  }
</style>
